import React, { useState } from "react"
import styled, { css } from "styled-components"
import { Link, graphql } from "gatsby"

import Layout from "../components/Layout"
import { Title, Container, Image, Text } from "../components/atoms"

const SidebarLayout = styled(Container)`
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    @media (min-width: 768px) {
        display: flex;
        align-items: flex-start;
        margin-top: 4em;
        margin-bottom: 4em;
    }
`

const ArticleContent = styled.article`
    @media (min-width: 768px) {
        flex: 0 0 70%;
        padding-left: 2em;
    }
    @media (min-width: 1024px) {
        padding-left: 4em;
    }
`

const Sidebar = styled.aside`
    @media (min-width: 768px) {
        flex: 0 0 30%;
        padding: 0.75em 1.5em;
        border: 2px solid #f4f4f4;
    }
    @media (min-width: 1024px) {
        flex: 0 0 30%;
        padding: 1.25em 2em;
    }
`

const FilterToggle = styled.button`
    display: block;
    background-color: #e7e7e7;
    color: #2b2b2b;
    padding: 1em;
    text-align: left;
    font-weight: 600;
    width: 100%;
    @media (min-width: 768px) {
        display: none;
    }
`

const MobileSubMenu = styled.nav`
    @media (max-width: 767px) {
        display: none;
        ${({ active }) =>
        active &&
        css`
                display: block;
            `}
    }
`

const MenuLink = styled(Link)`
    transition: color 0.25s ease;
    display: block;
    padding: 0.75em 0;
    text-decoration: none;
    &:link,
    &:visited {
        color: #2b2b2b;
    }
    &:hover {
        color: #ff6700;
    }
    @media (max-width: 767px) {
        background-color: #f4f4f4;
        padding: 0.75em 2em;
    }
`

const MenuHeader = styled(MenuLink)`
    font-weight: 600;
    @media (min-width: 768px) {
        font-size: 18px;
    }
    @media (max-width: 767px) {
        padding: 0.75em 1em;
    }
`

const Header = styled.header`
    background-color: #1e1e1e;
`;

const PageHeader = ({ title, hero }) => (
    <Header>
        <Container maxWidth="73.125em" gutter>
            <Title as="h1">{title}</Title>
            {hero ? <Image image={hero.image?.localFile?.childImageSharp?.gatsbyImageData} alt={hero.image?.altText} /> : null}
        </Container>
    </Header>
);

const Page = ({ data: { wpPage } }) => {
    const { title, hero, content, wpParent, wpChildren } = wpPage;
    const menuItems = wpChildren.nodes.length > 0 ? wpChildren.nodes : wpParent.node.wpChildren.nodes;

    const [active, setActive] = useState(false)

    const handleMenuTriggerClick = () => setActive(!active)

    return (
        <Layout>
            <main>
                <ArticleContent>
                    <PageHeader
                        title={title}
                        image={hero.image?.localFile?.childImageSharp?.gatsbyImageData}
                        imageAlt={hero.image?.altText}
                    />
                    <SidebarLayout forwardedAs="section" maxWidth="73.125em" gutter>
                        {menuItems.length > 0 && (
                            <Sidebar>
                                <FilterToggle onClick={handleMenuTriggerClick}>
                                    Menu
                                </FilterToggle>
                                <MobileSubMenu active={active}>
                                    <MenuHeader to="/the-club">The club</MenuHeader>
                                    {menuItems.map(({ id, title, uri }) => (
                                        <MenuLink key={id} to={uri}>
                                            {title}
                                        </MenuLink>
                                    ))}
                                </MobileSubMenu>
                            </Sidebar>
                        )}
                        <Text content={content} />
                    </SidebarLayout>
                </ArticleContent>
            </main>
        </Layout>
    )
}

export const query = graphql`
    query PageQuery($id: String) {
        wpPage(id: {eq: $id}) {
            title
            content
            hero {
                image {
                    localFile {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                    altText
                }
            }
            wpParent {
                node {
                    ... on WpPage {
                        wpChildren {
                            nodes {
                                id
                                ... on WpPage {
                                    title
                                    uri
                                }
                            }
                        }
                    }
                }
            }
            wpChildren {
                nodes {
                    id
                    uri
                    ... on WpPage {
                        title
                    }
                }
            }
        }
    }
`

export default Page